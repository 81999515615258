$(function () {
  'use strict';

  // ==========================================================================
  // 共通
  // ==========================================================================

  // 基本要素
  var $window = $(window);
  var $document = $(document);
  var $html = $(document.documentElement);
  var $body = $(document.body);
  var $page = $html.add($body);

  // メディアクエリ系
  var mediaQueries = {
    pc: 'only screen and (min-width: 768px)',
    pcLarge: 'only screen and (min-width: 992px)',
  };

  var matchMedia = {
    pc: window.matchMedia(mediaQueries.pc),
    pcLarge: window.matchMedia(mediaQueries.pcLarge),
  };

  var addMatchMediaEvent = function (callback, media) {
    if (matchMedia[media].addEventListener) {
      matchMedia[media].addEventListener('change', callback);
    } else {
      // deprecated
      matchMedia[media].addListener(callback);
    }
    callback();
  };

  // iphoneで2回タッチしないとリンクが飛ばない時の対処方法
  // https://nldot.info/if-you-dont-touch-the-iphone-twice-and-the-link-doesnt-fly-how-do-you-handle-it/
  window.ontouchstart = function () {};
  window.addEventListener('touchstart', function () {}, true);
  window.addEventListener('touchstart', function () {}, false);

  // 言語系
  var pageLang = document.documentElement.lang;

  // JSON系
  var jsonUrls = {
    portfolio: '/assets/template/cojp-2020/include/portfolio.json',
    news: '/assets/template/cojp-2020/include/news.json',
    btobMarketingColumns: '/assets/template/cojp-2020/include/btob-marketing-columns.json',
    ceoPickup: '/assets/template/cojp-2020/include/ceo-pickup.json',
    ceoContents: '/assets/template/cojp-2020/include/ceo-contents.json',
  };

  // Swiper系
  var swiperEasingSpeed = 600;

  // 共通 iPad viewport差し替え
  // ==========================================================================

  (function () {
    var ua = navigator.userAgent;
    var isSmartPhone = ua.indexOf('iPhone') > -1 || (ua.indexOf('Android') > -1 && ua.indexOf('Mobile') > -1);
    var isIpad = !isSmartPhone && (ua.indexOf('iPad') > -1 || (ua.indexOf('Macintosh') > -1 && 'ontouchend' in document));
    if (isIpad) {
      var viewportElem = document.getElementsByName('viewport')[0];
      viewportElem.setAttribute('content', 'min-width=1260');
    }
  })();

  // 共通 ヘッダー固定
  // ==========================================================================

  (function () {
    var $header = $('.renew-l-header');
    var $headerSpMain = $header.find('.renew-c-site-header-sp-main');
    var $headerPcMain = $header.find('.renew-c-site-header-pc-main');

    var onMediaChange = function () {
      var isMatchPcLarge = matchMedia.pcLarge.matches;
      $header.css('min-height', isMatchPcLarge ? $headerPcMain.height() : $headerSpMain.height());
    };

    addMatchMediaEvent(onMediaChange, 'pcLarge');
  })();

  // 共通 SPヘッダー
  // ==========================================================================

  (function () {
    var $headerSpMain = $('.renew-c-site-header-sp-main');
    var $headerSpMainButton = $headerSpMain.find('[href="#menu"]');
    var $headerSpMenu = $('.renew-c-site-header-sp-menu');
    var $headerSpOverlay = $('.renew-c-site-header-sp-overlay');

    var duration = 500;
    var easing = 'easeOutCubic';
    var openClassName = '-renew-modal-open';

    var isSpMenuActive;

    $headerSpMainButton.on('click', function (event) {
      event.preventDefault();

      if (!isSpMenuActive) {
        isSpMenuActive = true;
        $html.addClass(openClassName);
        $headerSpMainButton.addClass('-active');
        $headerSpMenu.stop(true, true).slideDown(duration, easing);
        $headerSpOverlay.stop(true, true).fadeIn(duration, easing);
      } else {
        isSpMenuActive = false;
        $html.removeClass(openClassName);
        $headerSpMainButton.removeClass('-active');
        $headerSpMenu.stop(true, true).slideUp(duration, easing);
        $headerSpOverlay.stop(true, true).fadeOut(duration, easing);
      }
    });
  })();

  // 共通 PCヘッダー
  // ==========================================================================

  (function () {
    var $headerPcMain = $('.renew-c-site-header-pc-main');
    var $headerPcMainLinks = $headerPcMain.find('.renew-c-site-header-pc-main__main-link');
    var $headerPcMainLinksForMenu = $headerPcMainLinks.filter('[data-menu-id]');
    var $headerPcMenu = $('.renew-c-site-header-pc-menu');
    var $headerPcMenuItems = $headerPcMenu.find('.renew-c-site-header-pc-menu__item');
    var $headerPcOverlay = $('.renew-c-site-header-pc-overlay');

    var timerDelay = 100;
    var duration = 500;
    var easing = 'easeOutCubic';

    var isPcButtonMouseEnter;
    var isPcMenuMouseEnter;
    var isPcButtonTouchStart;

    var lastMenuId = null;
    var isTouchDevice = window.matchMedia('(any-hover: none)');

    // 表示中ページ強調表示
    var pathname = new URL(location.href).pathname;
    $headerPcMainLinks.each(function () {
      var $link = $(this);
      var linkHref = $link.attr('href');
      var isPathMatch = pathname.search(linkHref) === 0;
      if (isPathMatch) {
        $link.addClass('-current');
      }
    });

    var hideMenu = function () {
      $headerPcMenu.stop(true, true).slideUp(duration, easing);
      $headerPcOverlay.stop(true, true).fadeOut(duration, easing);
      $headerPcMenu.removeClass('-show');
      bodyScrollLock.clearAllBodyScrollLocks();
    };

    $headerPcMainLinksForMenu.on('mouseenter mouseleave touchstart', function (event) {
      event.preventDefault();
      isPcButtonMouseEnter = event.type === 'mouseenter' || event.type === 'touchstart';
      isPcButtonTouchStart = event.type === 'touchstart';

      var $button = $(this);
      var menuId = $button.attr('data-menu-id');

      if (isPcButtonMouseEnter && menuId !== lastMenuId) {
        $headerPcMainLinksForMenu.removeClass('-active');
        $button.addClass('-active');

        var $menuItem = $headerPcMenuItems.filter('[data-menu-id="' + menuId + '"]');

        var isMenuItemChanged = (lastMenuId !== null) & (menuId !== lastMenuId);

        if (isMenuItemChanged) {
          $menuItem.show();
          var menuItemHeight = $menuItem.innerHeight();
          $menuItem.hide();

          var $lastMenuItem = $headerPcMenuItems.filter('[data-menu-id="' + lastMenuId + '"]');

          $lastMenuItem.stop(true, true).fadeOut(duration, easing);
          $menuItem.stop(true, true).fadeIn(duration, easing);

          $headerPcMenu.stop(true, true).animate(
              {
                height: menuItemHeight,
              },
              duration,
              easing
          );
        } else {
          $headerPcMenu.show();
          $menuItem.show();
          var menuItemHeight = $menuItem.innerHeight();
          $menuItem.hide();
          $headerPcMenu.hide();

          $headerPcMenuItems.hide();
          $menuItem.show();

          // $headerPcMenu.stop(true, true).slideDown(duration, easing);

          $headerPcMenu
              .stop(true, true)
              .css({
                display: 'block',
                height: 0,
              })
              .animate(
                  {
                    height: menuItemHeight,
                  },
                  duration,
                  easing
              );
          $headerPcOverlay.stop(true, true).fadeIn(duration, easing);
        }

        $headerPcMenu.addClass('-show');

        if (isTouchDevice.matches) {
          bodyScrollLock.disableBodyScroll($headerPcMenu.get(0));
        }

        lastMenuId = menuId;
      } else {
        window.setTimeout(function () {
          if ((!isPcButtonMouseEnter && !isPcMenuMouseEnter) || isPcButtonTouchStart) {
            $headerPcMainLinksForMenu.removeClass('-active');
            hideMenu();
            lastMenuId = null;
          }
        }, timerDelay);
      }
    });

    $headerPcMenu.on('mouseenter mouseleave', function (event) {
      isPcMenuMouseEnter = event.type === 'mouseenter';

      if (!isPcMenuMouseEnter) {
        window.setTimeout(function () {
          if (!isPcMenuMouseEnter && !isPcButtonMouseEnter) {
            $headerPcMainLinksForMenu.removeClass('-active');
            hideMenu();
            lastMenuId = null;
          }
        }, timerDelay);
      }
    });

    $headerPcOverlay.on('click', function () {
      $headerPcMainLinksForMenu.removeClass('-active');
      hideMenu();
      lastMenuId = null;
    });
  })();

  // 共通 トップに戻るボタン
  // ==========================================================================

  (function () {
    var $button = $('.renew-c-site-footer-pc__sub-backtotop');
    var $buttonSp = $('.renew-c-site-footer-sp__sub-backtotop');

    var thresholdPx = 200;
    var duration = 200;

    var latestStatus = null;

    var onScroll = function () {
      var currentY = $window.scrollTop();
      var currentStatus = currentY >= thresholdPx;
      if (currentStatus !== latestStatus) {
        if (currentStatus) {
          $button.fadeIn(duration).css('display', 'flex');
          $buttonSp.fadeIn(duration).css('display', 'flex');
        } else {
          $button.fadeOut(duration);
          $buttonSp.fadeOut(duration);
        }
      }
      latestStatus = currentStatus;
    };

    $window.on({
      load: onScroll,
      scroll: function () {
        window.requestAnimationFrame(onScroll);
      },
    });
    $body.on('touchmove', function () {
      window.requestAnimationFrame(onScroll);
    });
    onScroll();
  })();

  // 共通 スムーススクロール
  // ==========================================================================

  (function () {
    var headerHSp = 61;
    var headerHPc = 97;
    // var duration = 5000;
    // var easing = 'easeOutQuart';

    var scrollToElement = function (hash, animationFlag) {
      var targetY;
      var isPageTop = hash === '#' || hash === '#top';
      if (isPageTop) {
        targetY = 0;
      } else {
        var $target = $(hash);
        if (!$target.length) {
          return false;
        }
        var headerH = matchMedia.pcLarge ? headerHPc : headerHSp;
        targetY = $target.offset().top - headerH;
      }
      var scrollableY = $html.prop('scrollHeight') - $html.prop('clientHeight');
      var moveY = Math.min(scrollableY, targetY);
      // if (animationFlag) {
      //   $page.animate(
      //     {
      //       scrollTop: moveY,
      //     },
      //     duration,
      //     easing
      //   );
      // } else {
      $page.scrollTop(moveY);
      // }
    };

    $document.on('click', '.renew [href^="#"]:not([href="#"], .-disable-smooth-scroll)', function (event) {
      event.preventDefault();
      event.stopPropagation();
      var $link = $(this);
      $link.trigger('blur');
      scrollToElement($link.attr('href'), true);
    });

    var onloadHash = location.hash;
    if (onloadHash) {
      $window.on('load', function () {
        scrollToElement(onloadHash);
      });
      scrollToElement(onloadHash);
    }
  })();

  // 共通 要素複製
  // ==========================================================================

  (function () {
    $('.renew-js-placeholder').each(function () {
      var $wrapper = $(this);
      var selector = $wrapper.attr('data-from');
      $(selector).clone().appendTo($wrapper);
    });
  })();

  // 共通 要素フェード表示
  // ==========================================================================

  (function () {
    var onElemInview = function (entries) {
      entries.forEach(function (entry) {
        if (entry.isIntersecting) {
          entry.target.classList.add('renew-u-inview-active');
        }
      });
    };
    var options = {
      rootMargin: '0px 0px -100px',
    };
    var observer = new IntersectionObserver(onElemInview, options);
    var nodelist = document.querySelectorAll('[data-inview]');
    var node = Array.prototype.slice.call(nodelist, 0);
    node.forEach(function (elem) {
      observer.observe(elem);
      elem.classList.add('renew-u-inview-ready');
    });
  })();

  // 共通 アコーディオン
  // ==========================================================================

  (function () {
    var duration = 500;
    var easing = 'easeOutQuart';

    // 初期化処理
    var onLoad = function () {
      $('.js-accordion:not(.-loaded)').each(function () {
        var $box = $(this);

        // Vueテンプレート内要素対象外に
        var isVueTemplateInside = !!$box.closest('[v-cloak]').length;
        if (isVueTemplateInside) {
          return false;
        }
        $box.addClass('-loaded');

        $box.on('click', '.js-accordion-title', function (event) {
          event.preventDefault();
          var $boxTitle = $(this);
          var $boxBody = $boxTitle.siblings('.js-accordion-body');

          if ($boxTitle.is('.-active')) {
            $boxTitle.removeClass('-active');
            $boxBody.slideUp(duration, easing);
          } else {
            $boxTitle.addClass('-active');
            $boxBody.slideDown(duration, easing);
          }
        });
      });
    };

    // イベント設定+初回実行
    $window.on('load', onLoad);
    onLoad();
  })();

  // 共通 各種カード ロールオーバー
  // ==========================================================================

  (function () {
    var rolloverElems = [
      {
        // 事例カード
        $component: '.renew-c-card-works:not(.-with-vue)',
        linksSelector: '.renew-c-card-works__pic, .renew-c-button-more',
      },
      {
        // サービスカード
        $component: '.renew-c-card-services-article',
        linksSelector: '.renew-c-card-services-article__pic, .renew-c-button-more',
      },
      {
        // メンバー一覧カード（役員） ロールオーバー
        $component: '.renew-p-about-members-top-officer__list',
        linksSelector: '.renew-p-about-members-top-officer__img-link, .renew-p-about-members-top-officer__box-link, .-linked, .renew-c-button-more',
      },
      {
        // 共通 メンバー一覧カード（社員） ロールオーバー
        $component: '.renew-p-about-members-top-grid__item',
        linksSelector: '.renew-p-about-members-top-grid-card, .renew-c-button-more',
      },
      // {
      //   // 共通 メンバー一覧カード（社員：小カード） ロールオーバー
      //   $component: '.renew-p-about-members-top-card',
      //   linksSelector: '.renew .renew-p-about-members-top-card__item',
      // },s
    ];

    rolloverElems.forEach(function (rolloverElem) {
      var $component = $(rolloverElem.$component);
      var linksSelector = rolloverElem.linksSelector;
      $component.on('mouseover mouseleave', linksSelector, function (event) {
        $(event.delegateTarget)
            .find(linksSelector)
            .toggleClass('-active', event.type === 'mouseover');
      });
    });
  })();

  // 共通 セミナーカード ロールオーバー
  // ==========================================================================

  (function () {
    var $component = $('.renew-c-card-seminar-article:not(.-with-vue)');
    var linksSelector = '.renew-c-card-seminar-article__pic, .renew-c-button-more';
    $component.on('mouseover mouseleave', function (event) {
      $(event.delegateTarget)
          .find(linksSelector)
          .toggleClass('-active', event.type === 'mouseover');
    });
  })();

  // 共通 BtoBカード ロールオーバー
  // ==========================================================================

  (function () {
    var $component = $('.renew-c-card-btob-article:not(.-with-vue)');
    var linksSelector = '.renew-c-button-more';
    $component.on('mouseover mouseleave', function (event) {
      $(event.delegateTarget)
          .find(linksSelector)
          .toggleClass('-active', event.type === 'mouseover');
    });
  })();

  // 共通 BtoB資料ダウンロードページのカード ロールオーバー
  // ==========================================================================

  (function () {
    var $component = $('.renew-c-card-btob-downloads-article:not(.-with-vue)');
    var linksSelector = '.renew-c-card-btob-downloads-article__pic, .renew-c-button-more';
    $component.on('mouseover mouseleave', function (event) {
      $(event.delegateTarget)
          .find(linksSelector)
          .toggleClass('-active', event.type === 'mouseover');
    });
  })();

  // 共通 事例スライダー
  // ==========================================================================

  (function () {
    $('.renew-c-slider-works').each(function () {
      var $wrapper = $(this);
      var containerElem = $wrapper.find('.swiper-container').get(0);
      var buttonNextElem = $wrapper.find('.swiper-button-next').get(0);
      var buttonPrevElem = $wrapper.find('.swiper-button-prev').get(0);

      var isMediaPcActive = null;

      var swiperOptionSp = {
        slidesPerView: 1,
        loop: true,
        speed: swiperEasingSpeed,
        navigation: {
          nextEl: buttonNextElem,
          prevEl: buttonPrevElem,
        },
      };
      var swiperOptionPc = {
        slidesPerView: 3,
        slidesPerGroup: 3,
        watchOverflow: true,
        speed: swiperEasingSpeed,
        navigation: {
          nextEl: buttonNextElem,
          prevEl: buttonPrevElem,
        },
      };
      var mySwiper;

      // Swiper標準のbreakpoints切り替えを代替
      var onMediaChange = function () {
        var isMatchPc = matchMedia.pc.matches;
        if (isMediaPcActive !== isMatchPc) {
          if (mySwiper) {
            mySwiper.destroy();
          }
          mySwiper = new Swiper(containerElem, isMatchPc ? swiperOptionPc : swiperOptionSp);
        }
        isMediaPcActive = isMatchPc;
      };

      addMatchMediaEvent(onMediaChange, 'pc');
    });
  })();

  // 共通 サービススライダー
  // ==========================================================================

  (function () {
    $('.renew-c-slider-services-recommend').each(function () {
      var $wrapper = $(this);
      var containerElem = $wrapper.find('.swiper-container').get(0);
      var buttonNextElem = $wrapper.find('.swiper-button-next').get(0);
      var buttonPrevElem = $wrapper.find('.swiper-button-prev').get(0);

      var isMediaPcActive = null;

      var swiperOptionSp = {
        slidesPerView: 1,
        loop: true,
        speed: swiperEasingSpeed,
        navigation: {
          nextEl: buttonNextElem,
          prevEl: buttonPrevElem,
        },
      };
      var swiperOptionPc = {
        slidesPerView: 2,
        slidesPerGroup: 2,
        watchOverflow: true,
        speed: swiperEasingSpeed,
        navigation: {
          nextEl: buttonNextElem,
          prevEl: buttonPrevElem,
        },
      };
      var mySwiper;

      // Swiper標準のbreakpoints切り替えを代替
      var onMediaChange = function () {
        var isMatchPc = matchMedia.pc.matches;
        if (isMediaPcActive !== isMatchPc) {
          if (mySwiper) {
            mySwiper.destroy();
          }
          mySwiper = new Swiper(containerElem, isMatchPc ? swiperOptionPc : swiperOptionSp);
        }
        isMediaPcActive = isMatchPc;
      };

      addMatchMediaEvent(onMediaChange, 'pc');
    });
  })();

  // 共通 セミナースライダー
  // ==========================================================================

  (function () {
    $('.renew-c-slider-seminar-article').each(function () {
      var $wrapper = $(this);
      var containerElem = $wrapper.find('.swiper-container').get(0);
      var buttonNextElem = $wrapper.find('.swiper-button-next').get(0);
      var buttonPrevElem = $wrapper.find('.swiper-button-prev').get(0);

      var isMediaPcActive = null;

      var swiperOptionSp = {
        slidesPerView: 1,
        loop: true,
        speed: swiperEasingSpeed,
        navigation: {
          nextEl: buttonNextElem,
          prevEl: buttonPrevElem,
        },
      };
      var swiperOptionPc = {
        slidesPerView: 3,
        slidesPerGroup: 3,
        watchOverflow: true,
        speed: swiperEasingSpeed,
        navigation: {
          nextEl: buttonNextElem,
          prevEl: buttonPrevElem,
        },
      };
      var mySwiper;

      // Swiper標準のbreakpoints切り替えを代替
      var onMediaChange = function () {
        var isMatchPc = matchMedia.pc.matches;
        if (isMediaPcActive !== isMatchPc) {
          if (mySwiper) {
            mySwiper.destroy();
          }
          mySwiper = new Swiper(containerElem, isMatchPc ? swiperOptionPc : swiperOptionSp);
        }
        isMediaPcActive = isMatchPc;
      };

      addMatchMediaEvent(onMediaChange, 'pc');
    });
  })();

  // 共通 セミナー詳細スライダー
  // ==========================================================================

  (function () {
    $('.renew-c-slider-seminar-detail').each(function () {
      var $wrapper = $(this);
      var containerElem = $wrapper.find('.swiper-container').get(0);
      var buttonNextElem = $wrapper.find('.swiper-button-next').get(0);
      var buttonPrevElem = $wrapper.find('.swiper-button-prev').get(0);

      var isMediaPcActive = null;

      var swiperOptionSp = {
        slidesPerView: 1,
        loop: true,
        speed: swiperEasingSpeed,
        navigation: {
          nextEl: buttonNextElem,
          prevEl: buttonPrevElem,
        },
      };
      var swiperOptionPc = {
        slidesPerView: 3,
        slidesPerGroup: 3,
        watchOverflow: true,
        speed: swiperEasingSpeed,
        navigation: {
          nextEl: buttonNextElem,
          prevEl: buttonPrevElem,
        },
      };
      var mySwiper;

      // Swiper標準のbreakpoints切り替えを代替
      var onMediaChange = function () {
        var isMatchPc = matchMedia.pc.matches;
        if (isMediaPcActive !== isMatchPc) {
          if (mySwiper) {
            mySwiper.destroy();
          }
          mySwiper = new Swiper(containerElem, isMatchPc ? swiperOptionPc : swiperOptionSp);
        }
        isMediaPcActive = isMatchPc;
      };

      addMatchMediaEvent(onMediaChange, 'pc');
    });
  })();

  // 共通 Vueコンポーネント 要素フェード表示
  // ==========================================================================

  if (document.getElementById('renew-js-inview')) {
    Vue.component('renew-inview', {
      template: '#renew-js-inview',
      props: {
        tag: {
          default: 'div',
        },
      },
      data: function () {
        return {
          observer: null,
          inview: false,
        };
      },
      mounted: function () {
        var _this = this;
        var options = {
          rootMargin: '0px 0px -100px',
        };
        _this.observer = new IntersectionObserver(_this.onElemInview, options);
        _this.resetInview();
      },
      beforeDestroy: function () {
        var _this = this;
        _this.observer.unobserve(_this.$el);
      },
      methods: {
        onElemInview: function (entries) {
          var _this = this;
          if (entries[0].isIntersecting) {
            _this.inview = true;
          }
        },
        resetInview: function () {
          var _this = this;
          _this.observer.unobserve(_this.$el);
          _this.inview = false;
          _this.$nextTick(function () {
            _this.observer.observe(_this.$el);
          });
        },
        fourceInviewActive: function () {
          var _this = this;
          _this.inview = true;
        },
        onMouseover: function () {
          this.$emit('mouseover');
        },
        onMouseleave: function () {
          this.$emit('mouseleave');
        },
      },
    });
  }

  // 共通 Vueコンポーネント 事例カード
  // ==========================================================================

  if (document.getElementById('renew-js-card-works')) {
    Vue.component('renew-card-works', {
      template: '#renew-js-card-works',
      props: {
        work: {
          default: {},
        },
        type: {
          default: '',
        },
        bg: {
          default: 'white',
        },
        allClickable: {
          default: false,
        },
      },
      data: function () {
        return {
          isMoreLinkHover: false,
          pageLang: pageLang,
        };
      },
      methods: {
        hasDetailPage: function () {
          var _this = this;
          if (_this.pageLang === 'ja') {
            return _this.work.show_detail === '○';
          } else {
            return false;
          }
        },
        getDetailUrl: function () {
          var _this = this;
          return '/portfolio/' + _this.work.id + '/';
        },
        getImageUrlWebp: function () {
          var _this = this;
          if (_this.work.id) {
            return '/portfolio/img/common/thumb_' + _this.work.id + '.webp';
          }
          return 'https://dummyimage.com/373x220/ccc/fff'; // デザイン確認用ダミー画像
        },
        getImageUrlAvif: function () {
          var _this = this;
          if (_this.work.id) {
            return '/portfolio/img/common/thumb_' + _this.work.id + '.avif';
          }
          return 'https://dummyimage.com/373x220/ccc/fff'; // デザイン確認用ダミー画像
        },
        getServicesArray: function () {
          var _this = this;
          return _this.work.services.split('\n').filter(function (service) {
            return service !== '';
          });
        },
        getLinksArray: function () {
          var _this = this;
          var titles;
          var urls;

          if (_this.pageLang === 'ja') {
            titles = _this.work.link_titles.split('\n');
            urls = _this.work.link_urls.split('\n');
          } else {
            titles = _this.work[_this.pageLang + '_link_titles'].split('\n');
            urls = _this.work[_this.pageLang + '_link_urls'].split('\n');
          }

          var outputLinks = [];
          for (var i = 0, l = titles.length; i < l; i++) {
            if (titles[i] !== '' && urls[i] !== '') {
              outputLinks.push({
                title: titles[i],
                url: urls[i],
              });
            }
          }
          return outputLinks;
        },
        getTextByLang: function (work, name) {
          var _this = this;
          if (_this.pageLang === 'ja') {
            return work[name];
          } else {
            return work[_this.pageLang + '_' + name];
          }
        },
        isLinkExternal: function (url) {
          var _this = this;
          return !url.match(/^(\/|https:\/\/www\.intrix\.co\.jp\/)/);
        },
        onAnchorHover: function (isHovering) {
          var _this = this;
          _this.isMoreLinkHover = isHovering;
        },
        resetInview: function () {
          var _this = this;
          _this.$refs.inview.resetInview();
        },
        fourceInviewActive: function () {
          var _this = this;
          _this.$refs.inview.fourceInviewActive();
        },
      },
    });
  }

  // 共通 Vueコンポーネント 事例スライダー
  // ==========================================================================

  if (document.getElementById('renew-js-slider-works')) {
    Vue.use(VueAwesomeSwiper);
    Vue.component('renew-slider-works', {
      template: '#renew-js-slider-works',
      props: {
        filter: {
          default: 'detail',
        },
        currentId: {
          default: null,
        },
      },
      data: function () {
        return {
          defaults: {
            swiperOptionsSp: {
              slidesPerView: 1,
              loop: true,
            },
            swiperOptionsPc: {
              slidesPerView: 3,
              slidesPerGroup: 3,
              watchOverflow: true,
            },
          },
          options: {
            media: {
              pc: {
                mediaQuery: mediaQueries.pc,
                matchMedia: null,
              },
            },
          },
          results: {
            works: [],
            slider: {
              isMediaPc: null,
              isBeginning: null,
              isEnd: null,
              isLocked: null,
            },
          },
        };
      },
      created: function () {
        var _this = this;

        _this.options.media.pc.matchMedia = window.matchMedia(_this.options.media.pc.mediaQuery);
        if (_this.options.media.pc.matchMedia.addEventListener) {
          _this.options.media.pc.matchMedia.addEventListener('change', _this.onMediaChange);
        } else {
          // deprecated
          _this.options.media.pc.matchMedia.addListener(_this.onMediaChange);
        }
        _this.onMediaChange();

        $.ajax({
          url: jsonUrls.portfolio,
          cache: false,
        }).done(function (data) {
          _this.results.works = data;
        });
      },
      computed: {
        filteredWorks: function () {
          var _this = this;
          return _this.results.works.filter(function (work) {
            if (_this.currentId && _this.currentId === work.id) {
              return false;
            }
            return work['show_' + _this.filter] === '○';
          });
        },
      },
      methods: {
        onMediaChange: function () {
          var _this = this;
          _this.results.slider.isMediaPc = _this.options.media.pc.matchMedia.matches;
        },
        onSliderChange: function () {
          var _this = this;
          if (_this.results.slider.isMediaPc) {
            _this.results.slider.isBeginning = _this.$refs.mySwiperPc.$swiper.isBeginning;
            _this.results.slider.isEnd = _this.$refs.mySwiperPc.$swiper.isEnd;
            _this.results.slider.isLocked = _this.$refs.mySwiperPc.$swiper.isLocked;
          } else {
            _this.results.slider.isBeginning = false;
            _this.results.slider.isEnd = false;
            _this.results.slider.isLocked = false;
          }
        },
        onClickPrev: function () {
          var _this = this;
          if (_this.results.slider.isMediaPc) {
            _this.$refs.mySwiperPc.$swiper.slidePrev();
          } else {
            _this.$refs.mySwiperSp.$swiper.slidePrev();
          }
        },
        onClickNext: function () {
          var _this = this;
          if (_this.results.slider.isMediaPc) {
            _this.$refs.mySwiperPc.$swiper.slideNext();
          } else {
            _this.$refs.mySwiperSp.$swiper.slideNext();
          }
        },
      },
    });
  }

  // 共通 Vueコンポーネント BtoBカード
  // ==========================================================================

  if (document.getElementById('renew-js-card-btob')) {
    Vue.component('renew-card-btob', {
      template: '#renew-js-card-btob',
      props: {
        article: {
          default: {},
        },
      },
      data: function () {
        return {
          isMoreLinkHover: false,
        };
      },
      methods: {
        onAnchorHover: function (isHovering) {
          var _this = this;
          _this.isMoreLinkHover = isHovering;
        },
        resetInview: function () {
          var _this = this;
          _this.$refs.inview.resetInview();
        },
        fourceInviewActive: function () {
          var _this = this;
          _this.$refs.inview.fourceInviewActive();
        },
        parseDate: function (date) {
          var _this = this;
          return new Date(Date.parse(date));
        },
        getArticleDate: function (articleDate) {
          var _this = this;
          var parsedDate = _this.parseDate(articleDate);
          var outputYear = parsedDate.getFullYear();
          var outputMonth = parsedDate.getMonth() + 1;
          var outputDate = parsedDate.getDate();
          return outputYear + '年' + outputMonth + '月' + outputDate + '日';
        },
        getTagArray: function (tags) {
          var _this = this;
          return tags.split('\n').filter(function (tag) {
            return tag !== '';
          });
        },
      },
    });
  }

  // ==========================================================================
  // トップページ
  // ==========================================================================

  // トップページ メインビジュアル
  // ==========================================================================

  (function () {
    $('.renew-p-top-mainvisual').each(function () {
      var $wrapper = $(this);
      var containerElem = $wrapper.find('.swiper-container').get(0);

      var mySwiper = new Swiper(containerElem, {
        effect: 'fade',
        loopAdditionalSlides: 2,
        loop: true,
        allowTouchMove: false,
        fadeEffect: {
          crossFade: true,
        },
        speed: 2300,
        autoplay: {
          delay: 6000,
        },
      });
    });
  })();

  // トップページ RECOMMENDED SERVICES スライダー
  // ==========================================================================

  (function () {
    $('.renew-p-top-recommend-slider').each(function () {
      var $wrapper = $(this);
      var containerElem = $wrapper.find('.swiper-container').get(0);
      var buttonNextElem = $wrapper.find('.swiper-button-next').get(0);
      var buttonPrevElem = $wrapper.find('.swiper-button-prev').get(0);

      var isMediaPcActive = null;

      var swiperOptionSp = {
        slidesPerView: 1,
        loop: true,
        speed: swiperEasingSpeed,
        navigation: {
          nextEl: buttonNextElem,
          prevEl: buttonPrevElem,
        },
      };
      var swiperOptionPc = {
        slidesPerView: 4,
        slidesPerGroup: 4,
        watchOverflow: true,
        speed: swiperEasingSpeed,
        navigation: {
          nextEl: buttonNextElem,
          prevEl: buttonPrevElem,
        },
      };
      var mySwiper;

      // Swiper標準のbreakpoints切り替えを代替
      var onMediaChange = function () {
        var isMatchPc = matchMedia.pc.matches;
        if (isMediaPcActive !== isMatchPc) {
          if (mySwiper) {
            mySwiper.destroy();
          }
          mySwiper = new Swiper(containerElem, isMatchPc ? swiperOptionPc : swiperOptionSp);
        }
        isMediaPcActive = isMatchPc;
      };

      addMatchMediaEvent(onMediaChange, 'pc');
    });
  })();

  // トップページ OUR CLIENTS 画像の高さ調整
  // ==========================================================================

  (function () {
    $('.renew-p-top-clients-slider img').each(function (i, e) {
      var $this = $(this);
      var h = parseInt($this.attr('height')) / 2;
      h = Math.round(h);
      $this.height(h);
    });
  })();

  // トップページ OUR CLIENTS スライダー
  // ==========================================================================

  (function () {
    $('.renew-p-top-clients-slider').each(function () {
      var $wrapper = $(this);
      var containerElem = $wrapper.find('.swiper-container').get(0);

      var isMediaPcActive = null;

      var swiperOptionPc = {
        slidesPerView: 'auto',
        loop: true,
        speed: 4000,
        autoplay: {
          delay: 0,
        },
        allowTouchMove: false,
      };

      var mySwiper;

      var onMediaChange = function () {
        var isMatchPc = matchMedia.pc.matches;
        if (isMediaPcActive !== isMatchPc) {
          if (mySwiper) {
            mySwiper.destroy();
          }
          if (isMatchPc) {
            mySwiper = new Swiper(containerElem, swiperOptionPc);
          }
        }
        isMediaPcActive = isMatchPc;
      };

      addMatchMediaEvent(onMediaChange, 'pc');
    });
  })();

  // トップページ Vueコンポーネント LATEST NEWS
  // ==========================================================================

  if (document.getElementById('renew-js-top-news-latest')) {
    Vue.component('renew-top-news-latest', {
      template: '#renew-js-top-news-latest',
      data: function () {
        return {
          defaults: {
            posts_per_page: 3, // 表示件数
          },
          results: {
            articles: [],
          },
        };
      },
      created: function () {
        var _this = this;
        _this.getJson();
      },
      computed: {
        articlesWithLimit: function () {
          var _this = this;
          return _this.results.articles.slice(0, _this.defaults.posts_per_page);
        },
      },
      methods: {
        getJson: function () {
          var _this = this;
          $.ajax({
            url: jsonUrls.news,
            cache: false,
          }).done(function (data) {
            _this.results.articles = data.articles;
          });
        },
        parseDate: function (date) {
          var _this = this;
          return new Date(Date.parse(date));
        },
        getArticleDate: function (articleDate) {
          var _this = this;
          var parsedDate = _this.parseDate(articleDate);
          var outputYear = parsedDate.getFullYear();
          var outputMonth = parsedDate.getMonth() + 1;
          var outputDate = parsedDate.getDate();
          return outputYear + '年' + outputMonth + '月' + outputDate + '日';
        },
      },
    });
  }

  // トップページ Vueコンポーネント CASE STUDIES
  // ==========================================================================

  if (document.getElementById('renew-js-top-casestudies')) {
    Vue.use(VueAwesomeSwiper);
    Vue.component('renew-top-casestudies', {
      template: '#renew-js-top-casestudies',
      data: function () {
        return {
          defaults: {
            filter: 'top',
            posts_per_page: 6, // 表示件数
            swiperOptionsSp: {
              slidesPerView: 1,
              loop: true,
              speed: swiperEasingSpeed,
            },
            swiperOptionsPc: {
              slidesPerView: 'auto',
              watchOverflow: true,
              speed: swiperEasingSpeed,
            },
          },
          options: {
            media: {
              pc: {
                mediaQuery: mediaQueries.pc,
                matchMedia: null,
              },
            },
          },
          results: {
            works: [],
            slider: {
              isMediaPc: null,
              isBeginning: null,
              isEnd: null,
              isLocked: null,
            },
          },
        };
      },
      created: function () {
        var _this = this;

        _this.options.media.pc.matchMedia = window.matchMedia(_this.options.media.pc.mediaQuery);
        if (_this.options.media.pc.matchMedia.addEventListener) {
          _this.options.media.pc.matchMedia.addEventListener('change', _this.onMediaChange);
        } else {
          // deprecated
          _this.options.media.pc.matchMedia.addListener(_this.onMediaChange);
        }
        _this.onMediaChange();

        $.ajax({
          url: jsonUrls.portfolio,
          cache: false,
        }).done(function (data) {
          _this.results.works = data;
          _this.$nextTick(function () {
            _this.onSliderChange();
          });
        });
      },
      computed: {
        filteredWorks: function () {
          var _this = this;
          return _this
              .shuffleArray(
                  _this.results.works.filter(function (work) {
                    return work['show_' + _this.defaults.filter] === '○' && work.show_detail === '○';
                  })
              )
              .slice(0, _this.defaults.posts_per_page);
        },
      },
      methods: {
        onMediaChange: function () {
          var _this = this;
          _this.results.slider.isMediaPc = _this.options.media.pc.matchMedia.matches;
        },
        onSliderChange: function () {
          var _this = this;
          if (_this.results.slider.isMediaPc) {
            _this.results.slider.isBeginning = _this.$refs.mySwiperPc.$swiper.isBeginning;
            _this.results.slider.isEnd = _this.$refs.mySwiperPc.$swiper.isEnd;
            _this.results.slider.isLocked = _this.$refs.mySwiperPc.$swiper.isLocked;
          } else {
            _this.results.slider.isBeginning = false;
            _this.results.slider.isEnd = false;
            _this.results.slider.isLocked = false;
          }
        },
        onClickPrev: function () {
          var _this = this;
          if (_this.results.slider.isMediaPc) {
            _this.$refs.mySwiperPc.$swiper.slidePrev();
          } else {
            _this.$refs.mySwiperSp.$swiper.slidePrev();
          }
        },
        onClickNext: function () {
          var _this = this;
          if (_this.results.slider.isMediaPc) {
            _this.$refs.mySwiperPc.$swiper.slideNext();
          } else {
            _this.$refs.mySwiperSp.$swiper.slideNext();
          }
        },
        shuffleArray: function (array) {
          for (var i = array.length - 1; i > 0; i--) {
            var r = Math.floor(Math.random() * (i + 1));
            var tmp = array[i];
            array[i] = array[r];
            array[r] = tmp;
          }
          return array;
        },
      },
    });
  }

  // トップページ Vueコンポーネント BtoB Marketing Column
  // ==========================================================================

  if (document.getElementById('renew-js-top-btob')) {
    Vue.component('renew-top-btob', {
      template: '#renew-js-top-btob',
      data: function () {
        return {
          defaults: {
            posts_pickup: 1, // 表示件数
            posts_latest: 2, // 表示件数
          },
          results: {
            articles: [],
          },
        };
      },
      created: function () {
        var _this = this;
        _this.getJson();
      },
      computed: {
        pickupArticles: function () {
          var _this = this;
          var articles = _this.results.articles;
          // ピックアップフラグフィルタ
          articles = articles.filter(function (article) {
            return article.pickup === '○';
          });
          return articles.slice(0, _this.defaults.posts_pickup);
        },
        latestArticles: function () {
          var _this = this;
          var articles = _this.results.articles;
          // ピックアップフラグフィルタ
          articles = articles.filter(function (article) {
            return article.pickup !== '○';
          });
          return articles.slice(0, _this.defaults.posts_latest);
        },
      },
      methods: {
        getJson: function () {
          var _this = this;
          $.ajax({
            url: jsonUrls.btobMarketingColumns,
            cache: false,
          }).done(function (data) {
            _this.results.tags = data.tags;
            _this.results.articles = data.articles;
          });
        },
      },
    });
  }

  if (document.getElementById('renew-js-top-btob-article-pickup-card')) {
    Vue.component('renew-top-btob-article-pickup-card', {
      template: '#renew-js-top-btob-article-pickup-card',
      props: {
        article: {
          default: {},
        },
      },
      data: function () {
        return {
          isMoreLinkHover: false,
        };
      },
      methods: {
        onAnchorHover: function (isHovering) {
          var _this = this;
          _this.isMoreLinkHover = isHovering;
        },
        parseDate: function (date) {
          var _this = this;
          return new Date(Date.parse(date));
        },
        getArticleDate: function (articleDate) {
          var _this = this;
          var parsedDate = _this.parseDate(articleDate);
          var outputYear = parsedDate.getFullYear();
          var outputMonth = parsedDate.getMonth() + 1;
          var outputDate = parsedDate.getDate();
          return outputYear + '年' + outputMonth + '月' + outputDate + '日';
        },
      },
    });
  }

  if (document.getElementById('renew-js-top-btob-article-latest-card')) {
    Vue.component('renew-top-btob-article-latest-card', {
      template: '#renew-js-top-btob-article-latest-card',
      props: {
        article: {
          default: {},
        },
      },
      data: function () {
        return {
          isMoreLinkHover: false,
        };
      },
      methods: {
        onAnchorHover: function (isHovering) {
          var _this = this;
          _this.isMoreLinkHover = isHovering;
        },
        parseDate: function (date) {
          var _this = this;
          return new Date(Date.parse(date));
        },
        getArticleDate: function (articleDate) {
          var _this = this;
          var parsedDate = _this.parseDate(articleDate);
          var outputYear = parsedDate.getFullYear();
          var outputMonth = parsedDate.getMonth() + 1;
          var outputDate = parsedDate.getDate();
          return outputYear + '年' + outputMonth + '月' + outputDate + '日';
        },
      },
    });
  }

  // トップページ Vueコンポーネント NEWS
  // ==========================================================================

  if (document.getElementById('renew-js-top-news')) {
    Vue.component('renew-top-news', {
      template: '#renew-js-top-news',
      data: function () {
        return {
          defaults: {
            posts_per_page: 5, // 表示件数
          },
          results: {
            categories: [],
            articles: [],
          },
        };
      },
      created: function () {
        var _this = this;
        _this.getJson();
      },
      computed: {
        filteredImportantArticles: function () {
          var _this = this;
          var articles = _this.results.articles;
          // 重要フラグフィルタ
          articles = articles.filter(function (article) {
            return article.important === '○';
          });
          return articles;
        },
        filteredArticles: function () {
          var _this = this;
          var articles = _this.results.articles;
          // 重要フラグフィルタ
          articles = articles.filter(function (article) {
            return article.important !== '○';
          });
          return articles;
        },
        filteredArticlesWithLimit: function () {
          var _this = this;
          return _this.filteredArticles.slice(0, _this.defaults.posts_per_page);
        },
      },
      methods: {
        getJson: function () {
          var _this = this;
          $.ajax({
            url: jsonUrls.news,
            cache: false,
          }).done(function (data) {
            _this.results.categories = data.categories;
            _this.results.articles = data.articles;
          });
        },
        parseDate: function (date) {
          var _this = this;
          return new Date(Date.parse(date));
        },
        getArticleDate: function (articleDate) {
          var _this = this;
          var parsedDate = _this.parseDate(articleDate);
          var outputYear = parsedDate.getFullYear();
          var outputMonth = parsedDate.getMonth() + 1;
          var outputDate = parsedDate.getDate();
          return outputYear + '年' + outputMonth + '月' + outputDate + '日';
        },
      },
    });
  }

  // トップページ フロートバナー
  // ==========================================================================

  (function () {
    var $banner = $('#renew-p-top-float-banner');

    if (!$banner.length) {
      return false;
    }

    var $bannerPc = $('#renew-p-top-float-banner-pc');
    var $bannerSpButton = $('#renew-p-top-float-banner-sp-button');
    var $bannerPcButton = $('#renew-p-top-float-banner-pc-button');

    var ua = window.navigator.userAgent.toLowerCase();
    var isMatchPc;
    var windowWidth;
    var windowScrollBarWidth;
    var windowHeight;

    var $secLead = $('#lead');
    var $secFooter = $('.renew-l-footer').eq(0);
    var secLeadTop;
    var secLeadHeight;
    var secFooterTop;

    var pageWidthPc = 1260;
    var bannerBottomSp = 10;
    var bannerBottomPc = 183;
    var bannerHeight;
    var bannerOffsetSp = -10;
    var bannerOffsetPc = -10;

    var isSpClosed = false;
    var isPcExpanded = true;

    $bannerSpButton.on('click', function (event) {
      event.preventDefault();
      event.target.blur();
      isSpClosed = true;
      onScroll();
    });

    $bannerPcButton.on('click', function (event) {
      event.preventDefault();
      event.target.blur();
      isPcExpanded = !isPcExpanded;
      onScroll();
    });

    var onScroll = function () {
      var windowScrollTop = $window.scrollTop();
      var windowScrollLeft = $window.scrollLeft();
      var bannerBottom = isMatchPc ? bannerBottomPc : bannerBottomSp;
      var bannerTopSpace = windowHeight - bannerBottom - bannerHeight;
      var bannerOffset = isMatchPc ? bannerOffsetPc : bannerOffsetSp;
      var isAutoToggleActive = secLeadTop + secLeadHeight < windowScrollTop + bannerTopSpace + bannerOffset;

      var isBottomEnd = windowScrollTop + windowHeight >= secFooterTop + bannerBottom;
      $banner.toggleClass('-bottom', isBottomEnd);

      if (isMatchPc && windowWidth < pageWidthPc && isBottomEnd) {
        $banner.css('right', pageWidthPc - windowWidth + windowScrollBarWidth - windowScrollLeft + 'px');
      } else {
        $banner.css('right', '');
      }

      if (isMatchPc) {
        if (isAutoToggleActive) {
          if (isPcExpanded) {
            $banner.removeClass('-active').addClass('-active-expanded');
            $bannerPc.addClass('-active');
          } else {
            $banner.removeClass('-active-expanded').addClass('-active');
            $bannerPc.removeClass('-active');
          }
        } else {
          $banner.removeClass('-active -active-expanded');
          $bannerPc.removeClass('-active');
        }
      } else {
        if (isSpClosed) {
          $banner.removeClass('-active');
        } else {
          $banner.toggleClass('-active', isAutoToggleActive);
        }
      }
    };

    var onResize = function () {
      isMatchPc = matchMedia.pc.matches;
      windowWidth = window.innerWidth;
      windowScrollBarWidth = windowWidth - document.body.clientWidth;
      windowHeight = window.innerHeight;
      secLeadTop = $secLead.offset().top;
      secLeadHeight = $secLead.outerHeight();
      secFooterTop = $secFooter.offset().top;
      bannerHeight = $banner.outerHeight();
      onScroll();
    };

    // 2022.11 Firefox 106 最下部固定不具合対応用
    var timeStart = Date.now();
    var timer;
    var timerInterval = 500;
    var timerFn = function () {
      if (Date.now() - timeStart < 5999) {
        onResize();
        timer = setTimeout(timerFn, timerInterval);
      }
    };
    if (ua.indexOf('firefox') != -1) {
      timer = setTimeout(timerFn, timerInterval);
    }

    $window.on('scroll', function () {
      window.requestAnimationFrame(onScroll);
    });

    $window.on('load resize', function () {
      window.requestAnimationFrame(onResize);
    });

    onResize();
  })();

  // ==========================================================================
  // 事例
  // ==========================================================================

  // 事例 トップページ Vueコンポーネント 事例エリア
  // ==========================================================================

  if (document.getElementById('renew-js-portfolio-top-works')) {
    Vue.component('renew-portfolio-top-works', {
      template: '#renew-js-portfolio-top-works',
      data: function () {
        return {
          defaults: {
            // filter: 'top',
            pickupLength: 2,
          },
          options: {},
          results: {
            works: [],
            pickupIndexes: [],
          },
        };
      },
      created: function () {
        var _this = this;
        $.ajax({
          url: jsonUrls.portfolio,
          cache: false,
        }).done(function (data) {
          _this.results.works = data;
          _this.selectPickup();
        });
      },
      computed: {
        // filteredWorks: function() {
        //   var _this = this;
        //   return _this.results.works.filter(function(work) {
        //     return (work['show_' + _this.defaults.filter] === '○');
        //   });
        // },
        worksPickup: function () {
          var _this = this;
          // return _this.defaults.filteredWorks.filter(function(work, index) {
          return _this.results.works.filter(function (work, index) {
            var isPickedUp = _this.results.pickupIndexes.indexOf(index) !== -1;
            return isPickedUp;
          });
        },
        worksGallery: function () {
          var _this = this;
          // return _this.defaults.filteredWorks;
          return _this.results.works;
        },
      },
      methods: {
        selectPickup: function () {
          var _this = this;
          var pickupCandidate = [];
          // _this.defaults.filteredWorks.forEach(function(work, index) {
          _this.results.works.forEach(function (work, index) {
            if (work.show_top === '○') {
              pickupCandidate.push(index);
            }
          });
          _this.results.pickupIndexes = pickupCandidate.slice(0, _this.defaults.pickupLength);
        },
        shuffleArray: function (array) {
          for (var i = array.length - 1; i > 0; i--) {
            var r = Math.floor(Math.random() * (i + 1));
            var tmp = array[i];
            array[i] = array[r];
            array[r] = tmp;
          }
          return array;
        },
      },
    });
  }

  // 事例 トップページ Vueコンポーネント ピックアップ一覧
  // ==========================================================================

  if (document.getElementById('renew-js-portfolio-top-pickups')) {
    Vue.component('renew-portfolio-top-pickups', {
      template: '#renew-js-portfolio-top-pickups',
      props: ['works'],
    });
  }

  // 事例 トップページ Vueコンポーネント ピックアップ
  // ==========================================================================

  if (document.getElementById('renew-js-portfolio-top-pickup')) {
    Vue.component('renew-portfolio-top-pickup', {
      template: '#renew-js-portfolio-top-pickup',
      props: ['work'],
      methods: {
        hasDetailPage: function () {
          var _this = this;
          return _this.work.show_detail === '○';
        },
        getDetailUrl: function () {
          var _this = this;
          return '/portfolio/' + _this.work.id + '/';
        },
        getImageUrlWebp: function () {
          var _this = this;
          if (_this.work.id) {
            return '/portfolio/img/common/pickup_' + _this.work.id + '.webp';
          }
          return 'https://dummyimage.com/652x456/ccc/fff'; // デザイン確認用ダミー画像
        },
        getImageUrlAvif: function () {
          var _this = this;
          if (_this.work.id) {
            return '/portfolio/img/common/pickup_' + _this.work.id + '.avif';
          }
          return 'https://dummyimage.com/652x456/ccc/fff'; // デザイン確認用ダミー画像
        },
        getServicesArray: function () {
          var _this = this;
          return _this.work.services.split('\n').filter(function (service) {
            return service !== '';
          });
        },
      },
    });
  }

  // 事例 トップページ Vueコンポーネント ギャラリー
  // ==========================================================================

  if (document.getElementById('renew-js-portfolio-top-gallery')) {
    Vue.component('renew-portfolio-top-gallery', {
      template: '#renew-js-portfolio-top-gallery',
      props: ['works'],
      data: function () {
        return {
          defaults: {
            posts_per_page: 6, // 表示件数
            localStorageName: 'renew-portfolio-top-gallery',
          },
          options: {
            filter: {
              ALL: true,
            },
            media: {
              sp: {
                showLength: null,
              },
              pc: {
                mediaQuery: mediaQueries.pc,
                matchMedia: null,
                isActive: false,
              },
            },
            page: 1,
          },
          results: {
            worksCopy: [],
            worksCopyExceptLatest: [],
          },
        };
      },
      watch: {
        works: {
          immediate: true,
          handler: function () {
            var _this = this;
            _this.results.worksCopy = Object.assign([], _this.works);
            _this.results.worksCopyExceptLatest = Object.assign([], _this.works).slice(2);
            _this.onFilterChange();
          },
        },
        'options.filter': {
          handler: function () {
            var _this = this;
            localStorage.setItem(_this.defaults.localStorageName, JSON.stringify(_this.options.filter));
          },
          deep: true,
        },
      },
      created: function () {
        var _this = this;

        _this.options.media.pc.matchMedia = window.matchMedia(_this.options.media.pc.mediaQuery);
        if (_this.options.media.pc.matchMedia.addEventListener) {
          _this.options.media.pc.matchMedia.addEventListener('change', _this.onMediaChange);
        } else {
          // deprecated
          _this.options.media.pc.matchMedia.addListener(_this.onMediaChange);
        }
        _this.onMediaChange();
      },
      mounted: function () {
        var _this = this;
        window.addEventListener('pageshow', _this.onPageshow);
      },
      beforeDestroy: function () {
        var _this = this;
        window.removeEventListener('pageshow', _this.onPageshow);
      },
      computed: {
        filteredWorks: function () {
          var _this = this;
          var valueAll = 'ALL';
          if (_this.options.filter[valueAll]) {
            return _this.results.worksCopyExceptLatest;
          } else {
            return _this.results.worksCopy.filter(function (work) {
              var workServices = work.services.split('\n');
              var isMatched = false;
              Object.keys(_this.options.filter).forEach(function (key) {
                var filterItem = _this.options.filter[key];
                if (workServices.indexOf(key) >= 0 && filterItem) {
                  isMatched = true;
                }
              });
              return isMatched;
            });
          }
        },
        filteredWorksWithLimit: function () {
          var _this = this;

          var sliceByNumber = function (array, number) {
            var length = Math.ceil(array.length / number);
            return new Array(length).fill().map(function (_, i) {
              return array.slice(i * number, (i + 1) * number);
            });
          };

          var works;
          if (!_this.options.media.pc.isActive) {
            works = _this.filteredWorks.slice(0, _this.defaults.posts_per_page * _this.options.page);
          } else {
            works = _this.filteredWorks;
          }

          return sliceByNumber(works, _this.defaults.posts_per_page);
        },
        isLoadMoreButtonEnabled: function () {
          var _this = this;
          return _this.filteredWorks.length > _this.defaults.posts_per_page * _this.options.page;
        },
      },
      methods: {
        onPageshow: function (event) {
          var _this = this;
          if (window.performance.navigation.type === 2) {
            _this.getLocalStorage();
          }
        },
        onMediaChange: function () {
          var _this = this;
          var isMatchPc = _this.options.media.pc.matchMedia.matches;
          if (_this.options.media.pc.isActive !== isMatchPc) {
            _this.onFilterChange();
          }
          _this.options.media.pc.isActive = isMatchPc;
        },
        getLocalStorage: function () {
          var _this = this;
          var savedFiltersJson = localStorage.getItem(_this.defaults.localStorageName);
          if (!savedFiltersJson) {
            return false;
          }
          var savedFilters = JSON.parse(savedFiltersJson);
          _this.options.filter = savedFilters;
        },
        onFilterCheckboxClick: function (event) {
          var _this = this;
          var value = event.target.value;
          var valueAll = 'ALL';
          if (value === valueAll) {
            if (!_this.options.filter[valueAll]) {
              _this.$set(_this.options.filter, valueAll, true);
              Object.keys(_this.options.filter).forEach(function (key) {
                if (key !== valueAll) {
                  _this.$set(_this.options.filter, key, false);
                }
              });
              _this.onFilterChange(true);
            } else {
              event.preventDefault();
            }
          } else {
            _this.$set(_this.options.filter, valueAll, false);
            _this.$set(_this.options.filter, value, !_this.options.filter[value]);
            var isChecked = false;
            Object.keys(_this.options.filter).forEach(function (key) {
              if (_this.options.filter[key]) {
                isChecked = true;
              }
            });
            if (!isChecked) {
              _this.$set(_this.options.filter, valueAll, true);
            }
            _this.onFilterChange(true);
          }
        },
        onLoadMoreClick: function (event) {
          var _this = this;
          _this.options.page++;
        },
        onFilterChange: function (isManuallyChange) {
          var _this = this;
          _this.options.page = 1;
          _this.$nextTick(function () {
            if (_this.$refs.articles) {
              _this.$refs.articles.forEach(function (article) {
                article.resetInview();
              });
              // _this.$refs.inview.resetInview();
            }
          });
        },
      },
    });
  }

  // ==========================================================================
  // ニュース
  // ==========================================================================

  // ニュース トップページ Vueコンポーネント
  // ==========================================================================

  if (document.getElementById('renew-js-news-top')) {
    Vue.component('renew-news-top', {
      template: '#renew-js-news-top',
      data: function () {
        return {
          defaults: {
            posts_per_page: 10, // 表示件数
            localStorageName: 'renew-js-news-top',
          },
          options: {
            filters: {
              category: '',
              year: '',
            },
            page: 1,
          },
          results: {
            urlParamsEnabled: false,
            jsonLoaded: false,
            categories: [],
            articles: [],
          },
        };
      },

      watch: {
        'options.filters': {
          handler: function () {
            var _this = this;
            localStorage.setItem(_this.defaults.localStorageName, JSON.stringify(_this.options.filters));
          },
          deep: true,
        },
      },

      created: function () {
        var _this = this;
        _this.getUrlParams();
        _this.getJson();
      },
      mounted: function () {
        var _this = this;
        window.addEventListener('pageshow', _this.onPageshow);
      },
      beforeDestroy: function () {
        var _this = this;
        window.removeEventListener('pageshow', _this.onPageshow);
      },
      computed: {
        articleYears: function () {
          var _this = this;
          var years = [];
          var articles = _this.results.articles;

          // 重要フラグフィルタ
          articles = _this.results.articles.filter(function (article) {
            return article.important !== '○';
          });

          // 年リストアップ
          articles.forEach(function (article) {
            var parsedDate = _this.parseDate(article.date);
            var year = parsedDate.getFullYear();
            if (years.indexOf(year) === -1) {
              years.push(year);
            }
          });

          years.sort(function (a, b) {
            return b - a;
          });
          return years;
        },
        filteredImportantArticles: function () {
          var _this = this;
          var articles = _this.results.articles;

          // 重要フラグフィルタ
          articles = articles.filter(function (article) {
            return article.important === '○';
          });

          return articles;
        },
        filteredArticles: function () {
          var _this = this;
          var articles = _this.results.articles;

          // 重要フラグフィルタ
          articles = articles.filter(function (article) {
            return article.important !== '○';
          });

          // カテゴリフィルタ
          if (_this.options.filters.category && _this.options.filters.category !== '') {
            articles = articles.filter(function (article) {
              return article.category === _this.options.filters.category;
            });
          }

          // 年フィルタ
          if (_this.options.filters.year && _this.options.filters.year !== '') {
            articles = articles.filter(function (article) {
              var parsedDate = _this.parseDate(article.date);
              var year = parsedDate.getFullYear();
              return year === _this.options.filters.year;
            });
          }

          return articles;
        },
        filteredArticlesWithLimit: function () {
          var _this = this;
          var articles = _this.filteredArticles.slice(0, _this.defaults.posts_per_page * _this.options.page);

          var sliceByNumber = function (array, number) {
            var length = Math.ceil(array.length / number);
            return new Array(length).fill().map(function (_, i) {
              return array.slice(i * number, (i + 1) * number);
            });
          };

          return sliceByNumber(articles, _this.defaults.posts_per_page);
        },
        isMoreButtonEnable: function () {
          var _this = this;
          return _this.filteredArticles.length > _this.defaults.posts_per_page * _this.options.page;
        },
      },
      methods: {
        onPageshow: function (event) {
          var _this = this;
          if (window.performance.navigation.type === 2) {
            _this.getLocalStorage();
          }
        },
        getUrlParams: function () {
          var _this = this;
          var url = new URL(document.location);
          var urlParams = url.searchParams;
          var year = urlParams.get('year');
          if (year) {
            _this.options.filters.year = parseInt(year, 10);
            _this.results.urlParamsEnabled = true;
          }
        },
        getLocalStorage: function () {
          var _this = this;
          if (_this.results.urlParamsEnabled) {
            return false;
          }
          var savedFiltersJson = localStorage.getItem(_this.defaults.localStorageName);
          if (!savedFiltersJson) {
            return false;
          }
          var savedFilters = JSON.parse(savedFiltersJson);
          _this.options.filters = savedFilters;
        },
        getJson: function () {
          var _this = this;
          $.ajax({
            url: jsonUrls.news,
            cache: false,
          }).done(function (data) {
            _this.results.jsonLoaded = true;
            _this.results.categories = data.categories;
            _this.results.articles = data.articles;
          });
        },
        parseDate: function (date) {
          var _this = this;
          return new Date(Date.parse(date));
        },
        getArticleDate: function (articleDate) {
          var _this = this;
          var parsedDate = _this.parseDate(articleDate);
          var outputYear = parsedDate.getFullYear();
          var outputMonth = parsedDate.getMonth() + 1;
          var outputDate = parsedDate.getDate();
          return outputYear + '年' + outputMonth + '月' + outputDate + '日';
        },
        onFilterChange: function () {
          var _this = this;
          _this.options.page = 1;
          _this.$nextTick(function () {
            _this.$refs.articles.forEach(function (article) {
              article.resetInview();
            });
            // _this.$refs.articles.resetInview();
          });
        },
        onMoreButtonClick: function () {
          var _this = this;
          _this.options.page++;
          _this.$refs.moreButton.blur();
        },
      },
    });
  }

  // ==========================================================================
  // BtoB
  // ==========================================================================

  // BtoB トップページ Vueコンポーネント
  // ==========================================================================

  if (document.getElementById('renew-js-btob-top')) {
    Vue.component('renew-btob-top', {
      template: '#renew-js-btob-top',
      data: function () {
        return {
          defaults: {
            posts_per_page: 10, // 表示件数
            localStorageName: 'renew-js-btob-top',
          },
          options: {
            filters: {
              tags: { ALL: true },
            },
            page: 1,
          },
          results: {
            jsonLoaded: false,
            tags: [],
            articles: [],
          },
        };
      },
      watch: {
        'options.filters': {
          handler: function () {
            var _this = this;
            localStorage.setItem(_this.defaults.localStorageName, JSON.stringify(_this.options.filters));
          },
          deep: true,
        },
      },
      created: function () {
        var _this = this;
        _this.getJson();
      },
      mounted: function () {
        var _this = this;
        window.addEventListener('pageshow', _this.onPageshow);
      },
      beforeDestroy: function () {
        var _this = this;
        window.removeEventListener('pageshow', _this.onPageshow);
      },
      computed: {
        filteredArticles: function () {
          var _this = this;
          // タグフィルタ
          var valueAll = 'ALL';
          if (_this.options.filters.tags[valueAll]) {
            return _this.results.articles;
          } else {
            return _this.results.articles.filter(function (article) {
              var articlesTags = article.tags.split('\n');
              var isMatched = false;
              Object.keys(_this.options.filters.tags).forEach(function (key) {
                var filterItem = _this.options.filters.tags[key];
                if (articlesTags.indexOf(key) >= 0 && filterItem) {
                  isMatched = true;
                }
              });
              return isMatched;
            });
          }
        },
        filteredArticlesWithLimit: function () {
          var _this = this;
          var articles = _this.filteredArticles.slice(0, _this.defaults.posts_per_page * _this.options.page);

          var sliceByNumber = function (array, number) {
            var length = Math.ceil(array.length / number);
            return new Array(length).fill().map(function (_, i) {
              return array.slice(i * number, (i + 1) * number);
            });
          };

          return sliceByNumber(articles, _this.defaults.posts_per_page);
        },
        isMoreButtonEnable: function () {
          var _this = this;
          return _this.filteredArticles.length > _this.defaults.posts_per_page * _this.options.page;
        },
      },
      methods: {
        onPageshow: function (event) {
          var _this = this;
          if (window.performance.navigation.type === 2) {
            _this.getLocalStorage();
          }
        },
        getLocalStorage: function () {
          var _this = this;
          var savedFiltersJson = localStorage.getItem(_this.defaults.localStorageName);
          if (!savedFiltersJson) {
            return false;
          }
          var savedFilters = JSON.parse(savedFiltersJson);
          _this.options.filters = savedFilters;
        },
        getJson: function () {
          var _this = this;
          $.ajax({
            url: jsonUrls.btobMarketingColumns,
            cache: false,
          }).done(function (data) {
            _this.results.jsonLoaded = true;
            _this.results.tags = data.tags;
            _this.results.articles = data.articles;
          });
        },
        onFilterCheckboxClick: function (event) {
          var _this = this;
          var value = event.target.value;
          var valueAll = 'ALL';
          if (value === valueAll) {
            if (!_this.options.filters.tags[valueAll]) {
              _this.$set(_this.options.filters.tags, valueAll, true);
              Object.keys(_this.options.filters.tags).forEach(function (key) {
                if (key !== valueAll) {
                  _this.$set(_this.options.filters.tags, key, false);
                }
              });
              _this.onFilterChange();
            } else {
              event.preventDefault();
            }
          } else {
            _this.$set(_this.options.filters.tags, valueAll, false);
            _this.$set(_this.options.filters.tags, value, !_this.options.filters.tags[value]);
            var isChecked = false;
            Object.keys(_this.options.filters.tags).forEach(function (key) {
              if (_this.options.filters.tags[key]) {
                isChecked = true;
              }
            });
            if (!isChecked) {
              _this.$set(_this.options.filters.tags, valueAll, true);
            }
            _this.onFilterChange();
          }
        },
        onMoreButtonClick: function () {
          var _this = this;
          _this.options.page++;
          _this.$refs.moreButton.blur();
        },
        onFilterChange: function () {
          var _this = this;
          _this.options.page = 1;
          _this.$nextTick(function () {
            _this.$refs.articles.forEach(function (article) {
              article.resetInview();
            });
            // _this.$refs.articles.resetInview();
          });
        },
      },
    });
  }

  // BtoB 詳細ページ ページネーション Vueコンポーネント
  // ==========================================================================

  if (document.getElementById('renew-js-btob-detail-pagination')) {
    Vue.component('renew-btob-detail-pagination', {
      template: '#renew-js-btob-detail-pagination',
      data: function () {
        return {
          defaults: {},
          options: {},
          results: {
            articles: [],
          },
        };
      },
      created: function () {
        var _this = this;
        _this.getJson();
      },
      computed: {
        currentArticleIndex: function () {
          var _this = this;
          if (!_this.results.articles.length) {
            return;
          }
          var currentArticleIndex = null;
          var currentHref = location.href;
          var currentUrl = new URL(location.href);
          _this.results.articles.forEach(function (article, i) {
            var articleUrl = new URL(article.url, currentHref);
            if (currentUrl.origin === articleUrl.origin && currentUrl.pathname === articleUrl.pathname) {
              currentArticleIndex = i;
            }
          });
          return currentArticleIndex;
        },
        beforeArticle: function () {
          var _this = this;
          if (_this.currentArticleIndex === null) {
            return;
          }
          var beforeArticle = _this.results.articles[_this.currentArticleIndex + 1];
          if (beforeArticle) {
            return beforeArticle;
          }
        },
        afterArticle: function () {
          var _this = this;
          if (_this.currentArticleIndex === null) {
            return;
          }
          var afterArticle = _this.results.articles[_this.currentArticleIndex - 1];
          if (afterArticle) {
            return afterArticle;
          }
        },
      },
      methods: {
        getJson: function () {
          var _this = this;
          $.ajax({
            url: jsonUrls.btobMarketingColumns,
            cache: false,
          }).done(function (data) {
            _this.results.articles = data.articles;
          });
        },
      },
    });
  }

  // ==========================================================================
  // 代表コンテンツ
  // ==========================================================================

  // 代表コンテンツ トップページ Vueコンポーネント
  // ==========================================================================

  if (document.getElementById('renew-js-ceo-contents-top-works')) {
    Vue.component('renew-ceo-contents-top-works', {
      template: '#renew-js-ceo-contents-top-works',
      data: function () {
        return {
          defaults: {
            // filter: 'top',
            pickupLength: 2,
          },
          options: {},
          results: {
            works: [],
            pickupIndexes: [],
          },
        };
      },
      created: function () {
        var _this = this;
        $.ajax({
          url: jsonUrls.ceoPickup,
          cache: false,
        }).done(function (data) {
          _this.results.works = data;
          _this.selectPickup();
        });
      },
      computed: {
        worksPickup: function () {
          var _this = this;
          // return _this.defaults.filteredWorks.filter(function(work, index) {
          return _this.results.works.filter(function (work, index) {
            var isPickedUp = _this.results.pickupIndexes.indexOf(index) !== -1;
            return isPickedUp;
          });
        },
        worksGallery: function () {
          var _this = this;
          return _this.results.works;
        },
      },
      methods: {
        selectPickup: function () {
          var _this = this;
          var pickupCandidate = [];
          // _this.defaults.filteredWorks.forEach(function(work, index) {
          _this.results.works.forEach(function (work, index) {
            if (work.show_top === '○') {
              pickupCandidate.push(index);
            }
          });
          _this.results.pickupIndexes = pickupCandidate.slice(0, _this.defaults.pickupLength);
        },
        shuffleArray: function (array) {
          for (var i = array.length - 1; i > 0; i--) {
            var r = Math.floor(Math.random() * (i + 1));
            var tmp = array[i];
            array[i] = array[r];
            array[r] = tmp;
          }
          return array;
        },
      },
    });
  }

  // 代表コンテンツ トップページ Vueコンポーネント ピックアップ一覧
  // ==========================================================================

  if (document.getElementById('renew-js-ceo-contents-top-pickups')) {
    Vue.component('renew-ceo-contents-top-pickups', {
      template: '#renew-js-ceo-contents-top-pickups',
      props: ['works'],
    });
  }

  // 代表コンテンツ トップページ Vueコンポーネント ピックアップ
  // ==========================================================================

  if (document.getElementById('renew-js-ceo-contents-top-pickup')) {
    Vue.component('renew-ceo-contents-top-pickup', {
      template: '#renew-js-ceo-contents-top-pickup',
      props: ['work'],
      methods: {
        hasDetailPage: function () {
          var _this = this;
          return _this.work.show_detail === '○';
        },
        getDetailUrl: function () {
          var _this = this;
          return _this.work.link_urls;
        },
        getDetailTarget: function () {
          var _this = this;
          return _this.work.target === '○';
        },
        getImageUrlWebp: function () {
          var _this = this;
          if (_this.work.id) {
            return _this.work.id;
          }
          return 'https://dummyimage.com/652x456/ccc/fff'; // デザイン確認用ダミー画像
        },
        getImageUrlAvif: function () {
          var _this = this;
          if (_this.work.id) {
            return _this.work.id + '.avif';
          }
          return 'https://dummyimage.com/652x456/ccc/fff'; // デザイン確認用ダミー画像
        },
        getTagsArray: function () {
          var _this = this;
          return _this.work.tags.split('\n').filter(function (tag) {
            return tag !== '';
          });
        },
      },
    });
  }

    // 代表コンテンツ トップページ Vueコンポーネント ギャラリー
  // ==========================================================================

  if (document.getElementById('renew-js-ceo-contents-top-gallery')) {
    Vue.component('renew-ceo-contents-top-gallery', {
      template: '#renew-js-ceo-contents-top-gallery',
      data: function () {
        return {
          defaults: {
            posts_per_page: 10, // 表示件数
            localStorageName: 'renew-ceo-contents-top-gallery',
          },
          options: {
            filters: {
              tags: { ALL: true },
            },
            page: 1,
          },
          results: {
            jsonLoaded: false,
            tags: [],
            articles: [],
          },
        };
      },
      watch: {
        'options.filters': {
          handler: function () {
            var _this = this;
            localStorage.setItem(_this.defaults.localStorageName, JSON.stringify(_this.options.filters));
          },
          deep: true,
        },
      },
      created: function () {
        var _this = this;
        _this.getJson();
      },
      mounted: function () {
        var _this = this;
        window.addEventListener('pageshow', _this.onPageshow);
      },
      beforeDestroy: function () {
        var _this = this;
        window.removeEventListener('pageshow', _this.onPageshow);
      },
      computed: {
        filteredArticles: function () {
          var _this = this;
          // タグフィルタ
          var valueAll = 'ALL';
          if (_this.options.filters.tags[valueAll]) {
            return _this.results.articles.slice(2);
          } else {
            return _this.results.articles.filter(function (article) {
              var articlesTags = article.tags.split('\n');
              var isMatched = false;
              Object.keys(_this.options.filters.tags).forEach(function (key) {
                var filterItem = _this.options.filters.tags[key];
                if (articlesTags.indexOf(key) >= 0 && filterItem) {
                  isMatched = true;
                }
              });
              return isMatched;
            });
          }
        },
        filteredArticlesWithLimit: function () {
          var _this = this;
          var articles = _this.filteredArticles.slice(0, _this.defaults.posts_per_page * _this.options.page);

          var sliceByNumber = function (array, number) {
            var length = Math.ceil(array.length / number);
            return new Array(length).fill().map(function (_, i) {
              return array.slice(i * number, (i + 1) * number);
            });
          };

          return sliceByNumber(articles, _this.defaults.posts_per_page);
        },
        isMoreButtonEnable: function () {
          var _this = this;
          return _this.filteredArticles.length > _this.defaults.posts_per_page * _this.options.page;
        },
      },
      methods: {
        onPageshow: function (event) {
          var _this = this;
          if (window.performance.navigation.type === 2) {
            _this.getLocalStorage();
          }
        },
        getLocalStorage: function () {
          var _this = this;
          var savedFiltersJson = localStorage.getItem(_this.defaults.localStorageName);
          if (!savedFiltersJson) {
            return false;
          }
          var savedFilters = JSON.parse(savedFiltersJson);
          _this.options.filters = savedFilters;
        },
        getJson: function () {
          var _this = this;
          $.ajax({
            url: jsonUrls.ceoContents,
            cache: false,
          }).done(function (data) {
            _this.results.jsonLoaded = true;
            _this.results.tags = data.tags;
            _this.results.articles = data.articles;
          });
        },
        onFilterCheckboxClick: function (event) {
          var _this = this;
          var value = event.target.value;
          var valueAll = 'ALL';
          if (value === valueAll) {
            if (!_this.options.filters.tags[valueAll]) {
              _this.$set(_this.options.filters.tags, valueAll, true);
              Object.keys(_this.options.filters.tags).forEach(function (key) {
                if (key !== valueAll) {
                  _this.$set(_this.options.filters.tags, key, false);
                }
              });
              _this.onFilterChange();
            } else {
              event.preventDefault();
            }
          } else {
            _this.$set(_this.options.filters.tags, valueAll, false);
            _this.$set(_this.options.filters.tags, value, !_this.options.filters.tags[value]);
            var isChecked = false;
            Object.keys(_this.options.filters.tags).forEach(function (key) {
              if (_this.options.filters.tags[key]) {
                isChecked = true;
              }
            });
            if (!isChecked) {
              _this.$set(_this.options.filters.tags, valueAll, true);
            }
            _this.onFilterChange();
          }
        },
        onMoreButtonClick: function () {
          var _this = this;
          _this.options.page++;
          _this.$refs.moreButton.blur();
        },
        onFilterChange: function () {
          var _this = this;
          _this.options.page = 1;
          _this.$nextTick(function () {
            _this.$refs.articles.forEach(function (article) {
              article.resetInview();
            });
            // _this.$refs.articles.resetInview();
          });
        },
      },
    });
  }




  // 社長コンテンツ 詳細ページ ページネーション Vueコンポーネント
  // ==========================================================================

  // if (document.getElementById('renew-js-btob-detail-pagination')) {
  //   Vue.component('renew-btob-detail-pagination', {
  //     template: '#renew-js-btob-detail-pagination',
  //     data: function () {
  //       return {
  //         defaults: {},
  //         options: {},
  //         results: {
  //           articles: [],
  //         },
  //       };
  //     },
  //     created: function () {
  //       var _this = this;
  //       _this.getJson();
  //     },
  //     computed: {
  //       currentArticleIndex: function () {
  //         var _this = this;
  //         if (!_this.results.articles.length) {
  //           return;
  //         }
  //         var currentArticleIndex = null;
  //         var currentHref = location.href;
  //         var currentUrl = new URL(location.href);
  //         _this.results.articles.forEach(function (article, i) {
  //           var articleUrl = new URL(article.url, currentHref);
  //           if (currentUrl.origin === articleUrl.origin && currentUrl.pathname === articleUrl.pathname) {
  //             currentArticleIndex = i;
  //           }
  //         });
  //         return currentArticleIndex;
  //       },
  //       beforeArticle: function () {
  //         var _this = this;
  //         if (_this.currentArticleIndex === null) {
  //           return;
  //         }
  //         var beforeArticle = _this.results.articles[_this.currentArticleIndex + 1];
  //         if (beforeArticle) {
  //           return beforeArticle;
  //         }
  //       },
  //       afterArticle: function () {
  //         var _this = this;
  //         if (_this.currentArticleIndex === null) {
  //           return;
  //         }
  //         var afterArticle = _this.results.articles[_this.currentArticleIndex - 1];
  //         if (afterArticle) {
  //           return afterArticle;
  //         }
  //       },
  //     },
  //     methods: {
  //       getJson: function () {
  //         var _this = this;
  //         $.ajax({
  //           url: jsonUrls.btobMarketingColumns,
  //           cache: false,
  //         }).done(function (data) {
  //           _this.results.articles = data.articles;
  //         });
  //       },
  //     },
  //   });
  // }

  // ==========================================================================
  // イントリックスについて
  // ==========================================================================

  // イントリックスについて current判定
  // ==========================================================================

  (function () {
    $('.js-current-active').each(function () {
      var $currentPage = $(this);
      $currentPage.find('.renew-c-list-services-link__item').eq($currentPage.data('current-number')).addClass('-active');
    });
  })();

  // ==========================================================================
  // 共通 初期化
  // ==========================================================================

  // 共通 初期化 Vue
  // ==========================================================================

  (function () {
    var nodelist = document.querySelectorAll('.renew-js-vue');
    var node = Array.prototype.slice.call(nodelist, 0);
    node.forEach(function (rootElem) {
      var vueApp = new Vue({
        el: rootElem,
      });
    });
  })();
});
